
import LotuspayNach from "pages/NachPresentation/components/LotuspayNach";
import RazorpayAutoDebit from "pages/NachPresentation/components/RazorpayAutoDebit";
import React, { lazy } from "react";
import { ROUTES_VAR, PERMISSIONS, ROUTE_NAME } from "shared/constants";
// Pages
const PaymentView = lazy(() => import("pages/Payments/PaymentView"));
const DepositView = lazy(() => import("pages/Deposits/DepositView"));
const SubuserPaymentView = lazy(() =>
  import("pages/SubuserPayments/SubuserPaymentView")
);
const Configurations = lazy(() => import("pages/configuration"));
const NachDetails = lazy(() =>
  import("pages/NachPresentation/components/nachDetails")
);
const Vouchers = lazy(() => import("pages/Voucher"));
const DisburseStatictics = lazy(() =>
  import("pages/Disbursal/Component/disburseStatictics")
);
const Ift = lazy(() => import("pages/Disbursal/Component/IFT"));
const DisbursalAccounts = lazy(() =>
  import("pages/Disbursal/Component/disbursal-accounts")
);
const WhatsappReminder = lazy(() => import("pages/whatsappReminder"));
const Transactions = lazy(() => import("pages/Transactions"));
const Transaction = lazy(() => import("pages/Transactions/Transaction"));
const UserListing = lazy(() => import("pages/UsersView/UserListing"));
const PaymentListing = lazy(() => import("pages/Payments/PaymentListing"));
const Loans = lazy(() => import("pages/Loans"));
const Loan = lazy(() => import("pages/Loans/Loan"));
const CustomerView = lazy(() => import("pages/UsersView/CustomerView"));
const TestingPanel = lazy(() => import("pages/TestingPanel/TestingPanel"));
const SignIn = lazy(() => import("pages/auth/SignIn"));
const DocumentViewer = lazy(() => import("pages/Users/DocumentViewer"));
const Roles = lazy(() => import("pages/Roles"));
const Role = lazy(() => import("pages/Roles/Role"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const UtrCheck = lazy(() => import("pages/UtrCheck"));
const RefundListing = lazy(() => import("pages/Refund"));
const RefundView = lazy(() => import("pages/Refund/RefundView"));
const NachPresentation = lazy(() => import("pages/NachPresentation"));
const Statistics = lazy(() => import("pages/Statistics"));
const FileUpload = lazy(() => import("pages/FileUpload"));
const Deposits = lazy(() => import("pages/Deposits"));
const SubuserPayments = lazy(() => import("pages/SubuserPayments"));
const Koino = lazy(() => import("pages/koino"));
const CustomConfigurations = lazy(() => import("pages/CustomConfiguration"));
const MandateListing = lazy(() => import("pages/mandate"));
const DigiDataReport = lazy(() => import("pages/DigiReport"));
const EmailConfiguration = lazy(() => import("pages/EmailConfiguration"));
const UserSearchLisiting = lazy(() => import("pages/Users/UserSearch"));
const Disbursal = lazy(() => import("pages/Disbursal"));
const OutboundPayments = lazy(() =>
  import("pages/Disbursal/Component/OutboundDisburse")
);
const BBPSTransactions = lazy(() => import("pages/BBPS"));
const AddonTransaction = lazy(() => import("pages/AddOnTransaction"));
const Offers = lazy(() => import("pages/offers"));
const OfferView = lazy(() => import("pages/offers/component/offerView"));
const BNPL = lazy(() => import("pages/BNPL"));
const BnplVIew = lazy(() => import("pages/BNPL/components/bnplDetails"));
const Merchants = lazy(() => import("pages/Merchants"));
const MerchantView = lazy(() => import("pages/Merchants/Merchant"));
const ProductConfiguration = lazy(() => import("pages/ProductConfiguration"));
const Reports = lazy(() => import("pages/reports"));
const AccountBalance = lazy(() =>
  import("pages/Disbursal/Component/accountBalance")
);
const Financier = lazy(() => import("pages/Financier"));
const KFSFeeMasterPage = lazy(() => import("pages/KFSFeeMaster/index"));
const SourceAndDestinationAccount = lazy(() =>
  import("pages/Disbursal/Component/SourceAndDestinationAccountDetails")
);
const HiatusCallVerification = lazy(() =>
  import("pages/HiatusCallVerification")
);
const HiatusCaller = lazy(() =>
  import("pages/HiatusCallVerification/HiatusCaller")
);
const HiatusUnderWriter = lazy(() =>
  import("pages/HiatusCallVerification/HiatusUnderwriter")
);

const HiatusDoc = lazy(() =>
  import("pages/HiatusCallVerification/HiatusDocCaller")
);
const MerchantSettlementList = lazy(() =>
  import("pages/Merchants/Merchant-settlement")
);

const UserBankDetail = lazy(() => import("pages/OtherDetails/UserBankDetail"));
const Report = lazy(() => import("pages/Report"));
const SubuserMangement = lazy(() => import("pages/SubuserManagement"));

const UserDigitalFootprint = lazy(() => import("pages/UserDigitalFootprint"));
const BankListing = lazy(() => import("pages/BankView/BankListing"));
const Oauth = lazy(() => import("pages/auth/Oauth"));
const PendigSubuser = lazy(() => import("pages/PendingSubuser"));
const DisbrusalConfigurations = lazy(() => import("pages/Disbursal/Component/Configurations"));
const ActionLogs = lazy(() => import("pages/ActionLogs"))
const AccountStatements = lazy(() => import("pages/Disbursal/Component/AccountStatements"));
const customerDeatilsView = lazy(() => import("pages/CustomerDetails"));
const SettlementRefundRewards = lazy(() => import("pages/Disbursal/Component/SettlementRefundRewards"));
const OutboundRefundRewards = lazy(() =>
  import("pages/Disbursal/Component/OutboundRefundRewards")
);
const FinancierPaymentAccount = lazy(() => import("pages/FinancierPaymentAccount"));
const UniqueDescriptor = lazy(() => import("pages/UniqueDescriptor"));
const CustomerSupport = lazy(() => import("pages/CustomerSupport"));
const Basket = lazy(() => import("pages/Basket"))
const OfferListing = lazy(() => import("pages/OffersUnit"))
const MidManagement = lazy(() => import("pages/MidManagement"));
const TopupLoanSettlement = lazy(()=> import('pages/Disbursal/Component/TopupLoanSettlement'));
const TopupLoanOutbound = lazy(()=> import('pages/Disbursal/Component/TopupLoanOutbound'));
const LoanMatrix = lazy(() => import("pages/Loan-Matrix"));


export default [
  {
    path: ROUTES_VAR.signInOtp,
    exact: true,
    component: SignIn,
    sideDrawer: false,
    isPrivate: false,
    permission: PERMISSIONS.all,
    route: "",
  },

  {
    path: ROUTES_VAR.sign,
    exact: true,
    component: Oauth,
    sideDrawer: false,
    isPrivate: false,
    permission: PERMISSIONS.all,
    route: "",
  },
  {
    path: ROUTES_VAR.dashboard,
    exact: true,
    component: Dashboard,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.all,
    route: "/",
  },
  {
    path: ROUTES_VAR.statistics,
    exact: true,
    component: Statistics,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.statistics,
    permission: PERMISSIONS.statistics.hourly_basis_payments_count,
  },
  {
    path: ROUTES_VAR.loans,
    exact: true,
    component: Loans,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.loan.list,
    route: ROUTE_NAME.loan,
  },
  {
    path: ROUTES_VAR.loan,
    exact: true,
    component: Loan,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.loan.details,
    route: ROUTE_NAME.loan,
  },
  {
    path: ROUTES_VAR.users,
    exact: true,
    component: UserListing,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.user.list,
    route: ROUTE_NAME.user,
  },
  {
    path: ROUTES_VAR.user,
    exact: true,
    component: CustomerView,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.user.details,
    route: ROUTE_NAME.user,
  },
  {
    path: ROUTES_VAR.userSearch,
    exact: true,
    component: UserSearchLisiting,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.user.search_by_name,
    route: ROUTE_NAME.user,
  },
  {
    path: ROUTES_VAR.dhverification,
    exact: true,
    component: HiatusCallVerification,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME["dh-verification"],
    permission: PERMISSIONS["dh-verification"].getList,
  },
  {
    path: ROUTES_VAR.dhverificationlist,
    exact: true,
    component: HiatusCallVerification,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME["dh-verification"],
    permission: PERMISSIONS["dh-verification"].getList,
  },
  {
    path: ROUTES_VAR.dhverification_call,
    exact: true,
    component: HiatusCaller,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME["dh-verification"],
    permission: PERMISSIONS["dh-verification"].getListCaller,
  },
  {
    path: ROUTES_VAR.dhverification_underwriter,
    exact: true,
    component: HiatusUnderWriter,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME["dh-verification"],
    permission: PERMISSIONS["dh-verification"].getListUnderwriter,
  },
  {
    path: ROUTES_VAR.dhverification_doc,
    exact: true,
    component: HiatusDoc,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME["dh-verification"],
    permission: PERMISSIONS["dh-verification"].getListDocCaller,
  },

  {
    path: ROUTES_VAR.transactions,
    exact: true,
    component: Transactions,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.transaction.list,
    route: ROUTE_NAME.transaction,
  },
  {
    path: ROUTES_VAR.transaction,
    exact: true,
    component: Transaction,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.transaction.details,
    route: ROUTE_NAME.transaction,
  },

  {
    path: ROUTES_VAR.payments,
    exact: true,
    component: PaymentListing,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.payment.list,
    route: ROUTE_NAME.payment,
  },
  {
    path: ROUTES_VAR.subuserPayments,
    exact: true,
    component: SubuserPayments,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.subuserPayment.list,
    route: ROUTE_NAME.subuserPayments,
  },
  {
    path: ROUTES_VAR.subuserPayments_view,
    exact: true,
    component: SubuserPaymentView,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.subuserPayment.details,
    route: ROUTE_NAME.subuserPayments,
  },
  {
    path: ROUTES_VAR.deposits,
    exact: true,
    component: Deposits,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.deposit.list,
    route: ROUTE_NAME.deposit,
  },

  {
    path: ROUTES_VAR.deposit_view,
    exact: true,
    component: DepositView,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.deposit.details,
    route: ROUTE_NAME.deposit,
  },
  {
    path: ROUTES_VAR.payment_view,
    exact: true,
    component: PaymentView,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.payment.details,
    route: ROUTE_NAME.payment,
  },
  {
    path: ROUTES_VAR.check_utr,
    exact: true,
    component: UtrCheck,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.payment.check_utr,
    route: ROUTE_NAME.payment,
  },
  {
    path: ROUTES_VAR.refund,
    exact: true,
    component: RefundListing,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.refund.list,
    route: ROUTE_NAME.refund,
  },
  {
    path: ROUTES_VAR.refund_view,
    exact: true,
    component: RefundView,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.refund.details,
    route: ROUTE_NAME.refund,
  },
  {
    path: ROUTES_VAR.testing,
    exact: true,
    component: TestingPanel,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.testing,
    permission: PERMISSIONS.testing,
  },
  {
    path: ROUTES_VAR.nach,
    exact: true,
    component: NachPresentation,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.nach,
    permission: PERMISSIONS.nach.list,
  },
  {
    path: ROUTES_VAR.mandateDetail,
    exact: true,
    component: NachDetails,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.nach,
    permission: PERMISSIONS.nach.details,
  },
  {
    path: ROUTES_VAR.mandate,
    exact: true,
    component: MandateListing,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.nach,
    permission: PERMISSIONS.nach.nachList,
  },
  {
    path: ROUTES_VAR.koinoList,
    exact: true,
    component: Koino,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.koino,
    permission: PERMISSIONS.koino.upload_transit_user,
  },
  {
    path: ROUTES_VAR.reports,
    exact: true,
    component: Reports,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.reports,
    permission: PERMISSIONS.all,
  },

  {
    path: ROUTES_VAR.vouchers,
    exact: true,
    component: Vouchers,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.voucher,
    permission: PERMISSIONS.Disbursal.voucher_list,
  },
  {
    path: ROUTES_VAR.email,
    exact: true,
    component: EmailConfiguration,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.email_configuration,
    permission: PERMISSIONS.email_configuration.list,
  },
  {
    path: ROUTES_VAR.digi_report,
    exact: true,
    component: DigiDataReport,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.digiReport,
    permission: PERMISSIONS.digi_report,
  },
  {
    path: ROUTES_VAR.roles,
    exact: true,
    component: Roles,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.role.list,
    route: ROUTE_NAME.role,
  },

  {
    path: ROUTES_VAR.role,
    exact: true,
    component: Role,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.role.update,
    route: ROUTE_NAME.role,
  },
  {
    path: ROUTES_VAR.subusermanagementList,
    exact: true,
    component: SubuserMangement,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.subuser_management.get_list,
    route: ROUTE_NAME.subusermanagementList,
  },
  {
    path: ROUTES_VAR.pendingsubuser,
    exact: true,
    component: PendigSubuser,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.all,
    route: ROUTE_NAME["subuser-registration"],
  },
  {
    path: ROUTES_VAR.fileUpload,
    exact: true,
    component: FileUpload,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.fileUpload.list,
    route: ROUTE_NAME.fileUpload,
  },
  {
    path: ROUTES_VAR.whatsappReminder,
    exact: true,
    component: WhatsappReminder,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.fileUpload.list,
    route: ROUTE_NAME.fileUpload,
  },
  {
    path: ROUTES_VAR.configuration,
    exact: true,
    component: Configurations,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.configuration.list,
    route: ROUTE_NAME.configuration,
  },
  {
    path: ROUTES_VAR.customConfiguration,
    exact: true,
    component: CustomConfigurations,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.customConfig.list,
    route: ROUTE_NAME.customConfig,
  },
  {
    path: ROUTES_VAR.autoDisbursal,
    exact: true,
    component: Disbursal,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.auto_disbursements,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.outboundPayments,
    exact: true,
    component: OutboundPayments,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.list_outbound,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.accountBalance,
    exact: true,
    component: AccountBalance,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.bank_mid_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.disburseStatistics,
    exact: true,
    component: DisburseStatictics,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.disbursement_statistics,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.ift,
    exact: true,
    component: Ift,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.ift_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.bbpsListing,
    exact: true,
    component: BBPSTransactions,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.bbps.user_transaction_mis,
    route: ROUTE_NAME.all,
  },
  {
    path: ROUTES_VAR.addon,
    exact: true,
    component: AddonTransaction,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.addon.list,
    route: ROUTE_NAME.addon,
  },
  {
    path: ROUTES_VAR.offer,
    exact: true,
    component: Offers,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.offer.list,
    route: ROUTE_NAME.offer,
  },
  {
    path: ROUTES_VAR.offerView,
    exact: true,
    component: OfferView,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.offer.details,
    route: ROUTE_NAME.offer,
  },
  {
    path: ROUTES_VAR.bnpl,
    exact: true,
    component: BNPL,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.line.list,
    route: ROUTE_NAME.line,
  },
  {
    path: ROUTES_VAR.bnplView,
    exact: true,
    component: BnplVIew,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.line.details,
    route: ROUTE_NAME.line,
  },
  {
    path: ROUTES_VAR.merchants,
    exact: true,
    component: Merchants,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.merchant,
    permission: PERMISSIONS.merchant.list,
  },
  {
    path: ROUTES_VAR.merchantView,
    exact: true,
    component: MerchantView,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.merchant,
    permission: PERMISSIONS.merchant.details,
  },
  {
    path: ROUTES_VAR.productConfiguration,
    exact: true,
    component: ProductConfiguration,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.business_product_variants,
    permission: PERMISSIONS.business_product_variants.list,
  },
  {
    path: ROUTES_VAR.disburssalAccounts,
    exact: true,
    component: DisbursalAccounts,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.financier_account_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.financier,
    exact: true,
    component: Financier,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.offbook.utr_payments_mapping_bulk_upload,
    route: ROUTE_NAME.financier,
  },
  {
    path: ROUTES_VAR.kfsFeeMaster,
    exact: true,
    component: KFSFeeMasterPage,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.kfs.feesList,
    route: ROUTE_NAME["kfs-fee-master"],
  },
  {
    path: ROUTES_VAR.sourceDestinationAccounts,
    exact: true,
    component: SourceAndDestinationAccount,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.financier_account_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.nachLp,
    exact: true,
    component: LotuspayNach,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.nach,
    permission: PERMISSIONS.nach.list,
  },
  {
    path: ROUTES_VAR.nachRp,
    exact: true,
    component: RazorpayAutoDebit,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.nach,
    permission: PERMISSIONS.nach.list,
  },
  {
    path: ROUTES_VAR.merchantSettlement,
    exact: true,
    component: MerchantSettlementList,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.merchant_settlements_listing,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.usersBankDetails,
    exact: true,
    component: UserBankDetail,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS["bank-accounts"].list,
    route: ROUTE_NAME["bank-accounts"],
  },
  {
    path: ROUTES_VAR.report,
    exact: true,
    component: Report,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.report,
    permission: PERMISSIONS.reports.list,
  },
  {
    path: ROUTES_VAR.userDigitalFootprint,
    exact: true,
    component: UserDigitalFootprint,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS["user-digital-footprint"].request_list,
    route: ROUTE_NAME.userDigitalFootprint,
  },

  {
    path: ROUTES_VAR.banks,
    exact: true,
    component: BankListing,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.masterBank.list,
    route: ROUTE_NAME.masterBank,
  },
  {
    path: ROUTES_VAR.disburdalConfigurations,
    exact: true,
    component: DisbrusalConfigurations,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.configuration_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.actionLogs,
    exact: true,
    component: ActionLogs,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.actionlogs,
    permission: PERMISSIONS.adminActionLogs.listing,
  },
  {
    path: ROUTES_VAR.accountStatements,
    exact: true,
    component: AccountStatements,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.view_bank_statement,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.customerDeatilsView,
    exact: true,
    component: customerDeatilsView,
    isSideDrawer: false,
    isPrivate: true,
    permission: PERMISSIONS.all,
  },
  {
    path: ROUTES_VAR.settlementRefundRewards,
    exact: true,
    component: SettlementRefundRewards,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.refund_reward_settlement_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.outboundRefundRewards,
    exact: true,
    component: OutboundRefundRewards,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.refund_reward_outbound_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.uniqueDescriptor,
    exact: true,
    component: UniqueDescriptor,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.payment.unique_descriptor,
    route: ROUTE_NAME.payment,
  },
  {
    path: ROUTES_VAR.basketListing,
    exact: true,
    component: Basket,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.offerunit,
    permission: PERMISSIONS["offer-unit"].basketList,
  },
  {

    path: ROUTES_VAR.offerlisting,
    exact: true,
    component: OfferListing,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.offerunit,
    permission: PERMISSIONS["offer-unit"].list,
  },
  {
    path: ROUTES_VAR.customerSupport,
    exact: true,
    component: CustomerSupport,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.user,
    permission: PERMISSIONS.user.customer_search,
  },
  {
    path: ROUTES_VAR.financiers,
    exact: true,
    component: FinancierPaymentAccount,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS["financiers"].getPaymentAccounts,
    route: ROUTE_NAME.financiers,
  },
  {
    path: ROUTES_VAR.outboundRefundRewards,
    exact: true,
    component: OutboundRefundRewards,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.refund_reward_outbound_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.settlementRefundRewards,
    exact: true,
    component: SettlementRefundRewards,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.refund_reward_settlement_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.financiers,
    exact: true,
    component: FinancierPaymentAccount,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS["financiers"].getPaymentAccounts,
    route: ROUTE_NAME.financiers,
  },
  {
    path: ROUTES_VAR.topupLoanSettlement,
    exact: true,
    component: TopupLoanSettlement,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.top_up_settlement_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.topupLoanOutbound,
    exact: true,
    component: TopupLoanOutbound,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.Disbursal.top_up_oubound_list,
    route: ROUTE_NAME.Disbursal,
  },
  {
    path: ROUTES_VAR.loanMatrix,
    exact: true,
    component: LoanMatrix,
    isSideDrawer: true,
    isPrivate: true,
    route: ROUTE_NAME.grid,
    permission: PERMISSIONS.grid.getFresh,
  },
  {
    path: ROUTES_VAR.midManagement,
    exact: true,
    component: MidManagement,
    isSideDrawer: true,
    isPrivate: true,
    permission: PERMISSIONS.midManagement.midList,
    route: ROUTE_NAME.midManagement,
  },
  // {
  //   path: ROUTES_VAR.payment.link,
  //   exact: true,
  //   component: () => <div>Payment</div>,
  //   isSideDrawer: true,
  //   isPrivate: true,
  // },
  // {
  //   path: ROUTES_VAR.payment.payment,
  //   exact: true,
  //   component: () => <div>Payment sub</div>,
  //   isSideDrawer: true,
  //   isPrivate: true,
  // },
  // {
  //   path: ROUTES_VAR.payment.payment_view,
  //   exact: true,
  //   component: () => <div>Payment view</div>,
  //   isSideDrawer: true,
  //   isPrivate: true,
  // },
  // {
  //   path: ROUTES_VAR.payment.razorpay,
  //   exact: true,
  //   component: () => <div>razor</div>,
  //   isSideDrawer: true,
  //   isPrivate: true,
  // },
  // {
  //   path: ROUTES_VAR.payment.payu,
  //   exact: true,
  //   component: () => <div>payu</div>,
  //   isSideDrawer: true,
  //   isPrivate: true,
  // },
  // {
  //   path: ROUTES_VAR.payment.paytm,
  //   exact: true,
  //   component: () => <div>paytm</div>,
  //   isSideDrawer: true,
  //   isPrivate: true,
  // },
];
