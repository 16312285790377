import axiosInstance from "shared/axios";
import { TRANSACTION_API } from "shared/constants";
import { COLLECTION_API } from "shared/constants";
import { Financier_FileUpload_API } from "shared/constants";

const UPLOAD_URI = {
  listing: "/api/v1/bulk-upload/file/list",
  status: "/api/v1/bulk-upload",
};

export const uploadListing = (data = {}) => {
  return axiosInstance.post(UPLOAD_URI.listing, data);
};

// export const uploadStatus = (upload_ref_no) => {
//   return axiosInstance.get(`${UPLOAD_URI.status}/${upload_ref_no}/stats`);
// };
export const uploadStatus = (upload_ref_no, moduleParam='') => {
  let url = `${UPLOAD_URI.status}/${upload_ref_no}/stats`;
  let fullParam = moduleParam ? `${url}?module=${moduleParam}` : url;
  return axiosInstance.get(fullParam);
};

// export const downloadFile = (upload_ref_no) => {
//   return axiosInstance.get(
//     `${UPLOAD_URI.status}/${upload_ref_no}/response-file`
//   );
// };
export const downloadFile = (upload_ref_no, moduleParam='') => {
  let url = `${UPLOAD_URI.status}/${upload_ref_no}/response-file`;
  let fullParam = moduleParam ? `${url}?module=${moduleParam}` : url;
  return axiosInstance.get(fullParam);
};

export const interruptFileUpload = (upload_ref_no) => {
  return axiosInstance.post(`${UPLOAD_URI.status}/${upload_ref_no}/interrupt`);
};

export const softDeleteUpload = (formdata) => {
  return axiosInstance.post(
    `${TRANSACTION_API.listing}/soft-delete`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const lowPrioritySmBlockedupload = (param) => {
  return axiosInstance.post(
    `${COLLECTION_API.listing}/low-priority-bulk-upload`,
    param
  );
};
export const FinancierFileUpload = (param) => {
  return axiosInstance.post(
    `${Financier_FileUpload_API.upload_listing}/utr-payments-mapping-bulk-upload`,
    param
  );
};
