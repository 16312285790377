// for toasts
import { toast } from "react-toastify";
import querystring from "query-string";
import PropTypes from "prop-types";

// Config file
import { PERMISSIONS, toastConfig } from "./constants";
import { format as fnsformat } from "date-fns";
import { userActionLogs } from "shared/api/userActionLogs";
import store from "redux/index";
import Axios from "axios";


const toastList = new Set();
const MAX_TOAST = 1;

export const toastSuccess = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast.success(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};

export const toastInfo = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast.info(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};

export const toastWarning = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast.warn(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};

export const toastError = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast.error(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};


export const convertKeyIntoNormal = (key) => {
  let output = key.replace(/(^|_)(\w)/g, function ($0, $1, $2) {
    return ($1 && " ") + $2.toUpperCase();
  });
  return output;
};

export const toastDefault = (message) => {
  if (toastList.size < MAX_TOAST) {
    const id = toast(message, {
      ...toastConfig,
      onClose: () => toastList.delete(id),
    });
    toastList.add(id);
  }
};


const demo = (sts) => {
  return sts;
};

export const queryStringParsar = (qString) => {
  let parsed = {};
  if (!!qString) {
    const encoded = encodeURI(qString);
    const searchStringObj = querystring.parse(encoded, {
      encodeURIComponent: demo,
    });
    for (const key in searchStringObj) {
      if (!!key && !!searchStringObj[key]) {
        parsed[key] = searchStringObj[key];
      }
    }
  }
  return parsed;
};

export const formatDate = (dates) => {
  let datetime = new Date(dates);
  var hours = datetime.getHours();
  var minutes = datetime.getMinutes();
  var seconds = datetime.getSeconds();
  // var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var year = datetime.getFullYear();
  var month = datetime.getMonth();
  month = month < 10 ? "0" + month : month;
  var date = datetime.getDate();
  date = date < 10 ? "0" + date : date;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime =
    year +
    "-" +
    month +
    "-" +
    date +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  return strTime;
};
export const readableFormatDate = (dates) => {
  if (!dates) return "-";
  let dt = new Date(dates);
  if (isNaN(dt)) {
    return "-";
  }
  return fnsformat(dt, "do MMMM yyyy hh:mm:ss a");
};

export const readableFormatShortDate = (dates) => {
  if (!dates) return "-";
  let dt = new Date(dates);
  if (isNaN(dt)) {
    return "-";
  }
  return fnsformat(dt, "do MMM yyyy hh:mm a");
};

export const readableDate = (dates) => {
  if (!dates) return "-";
  let dt = new Date(dates);
  if (isNaN(dt)) {
    return "-";
  }
  return fnsformat(dt, "do MMMM yyyy");
};

export const readableMonthDate = (dates, format) => {
  if (!dates) return "-";
  let dt = new Date((dates + '').replace(' ', "T"));
  if (isNaN(dt)) {
    return '-';
  }
  // Default format to show only month and year
  return fnsformat(dt, format || "MMMM yyyy");
};

const Customformat = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
});

export const formatCurrency = (amount, skip) => {
  if (skip) {
    amount = Number(amount);
    return Customformat.format(amount);
  }
  if (amount) {
    amount = Number(amount);
  } else {
    return "-";
  }
  return Customformat.format(amount);
};

const CustomformatINR = new Intl.NumberFormat("en-in", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
});

export const formatCurrencyINR = (amount, skip) => {
  if (skip) {
    amount = Number(amount);
    return CustomformatINR.format(amount);
  }
  if (amount) {
    amount = Number(amount);
  } else {
    return "-";
  }
  return CustomformatINR.format(amount);
};

export const isObject = (o) => {
  return o !== null && typeof o === "object" && Array.isArray(o) === false;
};

export const nullToDash = (data) => {
  if (Array.isArray(data)) {
    return data.map((value) => {
      if (Array.isArray(value) || isObject(value)) return nullToDash(value);
      return value === null ? "-" : value;
    });
  } else if (isObject(data)) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key]) || isObject(data[key])) {
          data[key] = nullToDash(data[key]);
        } else {
          data[key] = data[key] === null ? "-" : data[key];
        }
      }
    }
  }
  return data;
};

/**
 *
 * @param {itemKey is data }
 * @param {value is key }
 */
export const IsEmpty = ({ itemKey, value, ...rest }) => {
  return itemKey &&
    itemKey.hasOwnProperty(value) &&
    itemKey[value] !== "" &&
    itemKey[value] !== null
    ? itemKey[value]
    : "-";
};

export const hasRoutePermission = (route, role, permission) => {
  if (role && role["id"] && role["id"] === 1) return true;
  if (permission && permission === PERMISSIONS.all) return true;
  if (
    role &&
    role["permission"][route] &&
    role["permission"][route].find((value) => value === permission)
  ) {
    return true;
  }

  return false;
};
export const openPaymentlink = (link) => {
  window.open(link, "_blank");
};

const addZero = (str) => {
  if (String(str).length === 1) {
    return `0${str}`;
  }
  return str;
};
export const formatApiDate = (date) => {
  if (!date) return "-";
  let d = new Date(date);

  return `${d.getFullYear()}-${addZero(d.getMonth() + 1)}-${addZero(
    d.getDate()
  )} ${addZero(d.getHours())}:${addZero(d.getMinutes())}:${addZero(
    d.getSeconds()
  )}`;
};

export const formatDateinHr = (date) => {
  if (!date) return "";
  let d = new Date(date);

  return `${d.getFullYear()}-${addZero(d.getMonth() + 1)}-${addZero(
    d.getDate()
  )} ${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
};

export const queryparamBuilder = (param = {}) => {
  let str = "";
  if (!Object.keys(param).length) return str;

  for (const key in param) {
    if (param.hasOwnProperty(key)) {
      if (param[key] === 0 || param[key]) {
        str += `${key}=${param[key]}&`;
      }
    }
  }
  return str;
};

// export const getQueryParam = (query) => {
//   console.log('getQueryParam', query)
//   let serachUrl = "?";
//   for (const key in query) {
//     if (query.hasOwnProperty(key)) {
//       if (query[key] && query[key].length > 0) {
//         if (key === "selected" && query["search"]) {
//           console.log('inner if')
//           serachUrl += `${query["selected"]}=${query["search"]}&`;
//         } else if (key !== "search" && key !== "selected") {
//           serachUrl += `${key}=${query[key]}&`;
//         }
//       }
//     }
//   }
//   console.log('searchUrl',serachUrl)
//   return serachUrl;
// };

export const getQueryParam = (query, skipKey = false) => {
  let serachUrl = '?';
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      if (query[key] && query[key].length > 0) {
        if (key === "selected" && query['search'] && !skipKey) {
          serachUrl += `${query['selected']}=${query['search']}&`;
        } else if ((key !== "search" && key !== "selected") || skipKey) {
          serachUrl += `${key}=${query[key]}&`;
        }
      }
    }
  }
  return serachUrl;
}

export const getFullAddress = (address) => {
  const { room_number, line_1, line_2, landmark, pincode, city } = address;
  let str = "";
  if (room_number) {
    str = str.concat(room_number + ",");
  }
  if (line_1) {
    str = str.concat(line_1 + ",");
  }
  if (line_2) {
    str = str.concat(line_2 + ",");
  }
  if (landmark) {
    str = str.concat(landmark + ",");
  }
  if (city) {
    str = str.concat(city + ",");
  }
  if (pincode) {
    str = str.concat(pincode + ",");
  }
  return str;
};
export const convertNumberIntoDesiredLength = (number, desiredLength) => {
  var str = "" + number;
  if (str.length < desiredLength)
    str = new Array(desiredLength - str.length).join("0") + str;
  return str;
};

export const TextCopy = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      toastSuccess(`Text copied ${text}`);
    });
  }
};

export const UserAction = async (action, id, ...args) => {
  const {
    auth: { user },
  } = store.getState();
  const { token, ...restUserDetail } = user
  const { data } = await Axios.get('https://geolocation-db.com/json/');
  // console.log(process.env.REACT_APP_IS_DEV);
  let path = window.location.pathname.slice(1);
  const items = path.split('/');
  const tempsArr = [...items];
  tempsArr.pop();
  const itemLength = items.length;
  const item = {};
  item['subuser_reference_number'] = user.subuser_reference_number;
  item['action'] = action || items[itemLength - 2];
  item['sub_user_id'] = id || items[itemLength - 1];
  item['main_id'] = id || items[itemLength - 1];
  item['action_string'] = `${user.name} have visited ${action || tempsArr.join(' ')} with id ${item.sub_user_id}`
  item['dependent_id'] = { ...data, user: { ...restUserDetail } }
  if (args.length > 0) {
    item.dependent_id = { ...item.dependent_id, payload: { ...args[0] } }
  }
  if (!item.action)
    return;
  try {
    await userActionLogs(item);
  } catch (err) {
    console.log(err.message);
  }
}

export const formatOnlyDate = (dates, format) => {
  let dt = new Date((dates + '').replace(' ', "T"));
  if (isNaN(dt)) {
    return '-'
  }
  return fnsformat(dt, format || "yyyy-MM-dd");
};

export default {
  queryStringParsar,
  toastDefault,
  toastError,
  toastWarning,
  toastInfo,
  toastSuccess,
  formatDate,
  formatCurrency,
  formatCurrencyINR,
  readableFormatDate,
  readableDate,
  formatOnlyDate
};
hasRoutePermission.propTypes = {
  route: PropTypes.string,
  role: PropTypes.any,
  permission: PropTypes.string,
};
formatCurrency.propTypes = {
  amount: PropTypes.any,
  skip: PropTypes.bool,
};
formatCurrencyINR.propTypes = {
  amount: PropTypes.any,
  skip: PropTypes.bool,
};
