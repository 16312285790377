import axiosInstance from "shared/axios";

const SUBUSER_URI = {
  listing: "/api/v1/subuser/payments",
  global_IsDm: "/api/v1/subuser",
  pending_listing: "/api/v1/subuser-registration",
  Approve_subuser: "/api/v1/subuser-registration/status-update",
  department: "/api/v1/subusers/registration/departments",
  create_form: "/api/v1/subusers/registration",
  roles: "/api/v1/subusers/registration/roles",
  reporting: "api/v1/subusers/registration/user-search"
};

export const subuserPaymentListing = (select, serach, subUserRefNo, offset) => {
  let url = `${select}=${serach}&offset=${offset}`;
  if (subUserRefNo) {
    url = url + `&search_by_subuser=${subUserRefNo}`;
  }
  return axiosInstance.get(`${SUBUSER_URI.listing}?${url}`);
};

export const subuserPaymentDetails = (ref_no) => {
  return axiosInstance.get(`${SUBUSER_URI.listing}/${ref_no}`);
};

export const captureRazorpayPayment = (payment_ref_no) => {
  return axiosInstance.post(
    `${SUBUSER_URI.listing}/${payment_ref_no}/capture-payment`
  );
};

export const verifySubUserPayment = (ref, param) => {
  return axiosInstance.post(`${SUBUSER_URI.listing}/${ref}/verify`, param);
};

export const getUserGlobalDm = () => {
  let url = `${SUBUSER_URI.global_IsDm}`;
  return axiosInstance.get(url);
};
export const getsubuserPendingListing = (param) => {
  let url =
    Object.keys(param).length !== 0
      ? `${SUBUSER_URI.pending_listing}${param}`
      : `${SUBUSER_URI.pending_listing}`;
  return axiosInstance.get(url);
};
export const ApproveSubuserPending = (param, payload) => {
  let url =
    Object.keys(param).length !== 0
      ? `${SUBUSER_URI.Approve_subuser}
      ${param}`
      : `${SUBUSER_URI.Approve_subuser}
      `;
  return axiosInstance.post(url, payload);
};



export const getsubuserPendingAllDepartmentListing = () => {
  return axiosInstance.get(
    `${SUBUSER_URI.department}`,
  );
};


export const getsubuserPendingAllRolesListing = (param) => {
  let url =
    Object.keys(param).length !== 0
      ? `${SUBUSER_URI.roles}${param}`
      : `${SUBUSER_URI.roles}`;

  return axiosInstance.get(
    url,
  )
};



export const getsubuserPendingManagerReportingListing = (param) => {
  let url =
    Object.keys(param).length !== 0
      ? `${SUBUSER_URI.reporting}${param}`
      : `${SUBUSER_URI.reporting}`;

  return axiosInstance.get(
    url,
  );
};

export const createApprovalFrom = (param) => {
  return axiosInstance.post(`${SUBUSER_URI.create_form}`, param);
}
