import React, { useEffect, useState } from "react";
import { Grid, Button, makeStyles, Chip } from "@material-ui/core";
import CustomSelect from "components/custom/CustomSelect";
import RightSideDrawer from "../RightSideDrawer";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import InputField from "components/ui/form/InputField";
import { ROUTES_VAR, ROUTE_NAME } from "shared/constants";
import { KisshtDialog } from "../../custom/KisshtDialog";
import SoftDelete from "shared/components/softdelete";
import Lowprioritysmblocked from "shared/components/lowprioritysmblocked";
import { PERMISSIONS } from "../../../shared/constants";
import KisshtButton from "components/ui/KisshtButton";
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import { orange } from "@material-ui/core/colors";
import { Search } from "@material-ui/icons";
import { getUserGlobalDm } from "shared/api/subuser";




const ROLE_ID_AUDITOR = process.env.REACT_APP_NAME === "DEV" ? [65,66,70, 110] : [63,64,69, 73];
const ROLE_ACCOUNTS = process.env.REACT_APP_NAME === "DEV" ? [8,67] : [8,66];

const useStyle = makeStyles((_) => ({
  root: {
    "& .MuiAlert-root": {
      border: '#fff'
    }
  },
  search: {
    "&  input": {
      padding: "0.5rem",
      maxwidth: "200px",
    },
  },
  chipPrimary: {
    color: orange
  }
}));


export const GlobalHeader = (_) => {
  const classes = useStyle();
  const [model, setModel] = useState(false);
  const [smBlockedModal, setsmBlockedModal] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [isData, setIsData] = useState({});
  const [showHeader, setShowHeader] = useState(false)






  useEffect(() => {
    setSpinning(true);

    getUserGlobalDm()
      .then((res) => {

        setSpinning(false);
        if (res.hasOwnProperty("success") && res.success) {
          const { subuser } = res.data;
          setIsData(subuser);
          setShowHeader(true)

        }
      })
      .catch((error) => {
        // setIsData([]);
        setSpinning(false);
      });
  }, []);



  const scheme = Yup.object().shape({
    search: Yup.string()
      .required("Field should not be empty")
      .trim("Field should not be empty"),
  });
  const handleStatus = (e, setFieldValue) => {
    e.persist();
    if (e) {
      let item = e.target.value;
      setFieldValue("search", "");
      setFieldValue("status", item);
    }
  };
  const handleSubmit = (values) => {
    let url = "";
    if (values.search) {
      switch (values.status) {
        case ROUTE_NAME.user:
          url = `${ROUTES_VAR.users}/${values.search}`;
          break;
        case ROUTE_NAME.loan:
          url = `${ROUTES_VAR.loans}/${values.search}`;
          break;
        case ROUTE_NAME.payment:
          url = `${ROUTES_VAR.payments}/${values.search}`;
          break;
        case ROUTE_NAME.transaction:
          url = `${ROUTES_VAR.transactions}/${values.search}`;
          break;
      }
    }
    window.open(url, "_blank");
  };

  const handleOption = () => {
    if(ROLE_ACCOUNTS.includes(isData.role_id)){
     return {
        transaction: "Transaction Reference no",
        user: "User Reference No",
        loan: "Loan Reference No",
    }
  }
    else{
      return {
        transaction: "Transaction Reference no",
        user: "User Reference No",
        loan: "Loan Reference No",
        payment: "Payment Reference No",
    }
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          status: "",
          search: "",
        }}
        validationSchema={scheme}
        onSubmit={handleSubmit}
      >
        {({ values, touched, setFieldValue }) => (
          <Form className="w-full">
            <Grid container alignItems="center">
              <Grid item xs={8} sm={8} md={8}>
                {!ROLE_ID_AUDITOR.includes(isData.role_id) && showHeader ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <CustomSelect
                        onChange={(e) => handleStatus(e, setFieldValue)}
                        maxwidth="200px"
                        multiple={false}
                        nulloption="Search By"
                        value={values.status}
                        options={handleOption()}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        variant="outlined"
                        className={classes.search}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        id="search"
                        placeholder="Search"
                        name="search"
                        component={InputField}
                        touched={touched}
                        value={values.search}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<Search />}
                        variant="contained"
                        className="shadow-none"
                        color="primary"
                        size="medium"
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item>
                      <KisshtButton
                        size="medium"
                        variant="outlined"
                        route={ROUTE_NAME.transaction}
                        permission={PERMISSIONS.transaction.softDelete}
                        onClick={() => setModel(true)}
                      >
                        Soft Delete
                      </KisshtButton>
                    </Grid>
                    <Grid item>
                      <KisshtButton
                        size="medium"
                        variant="outlined"
                        route={ROUTE_NAME.collections}
                        permission={PERMISSIONS.collections.lowPriorityBulkUpload}
                        onClick={() => setsmBlockedModal(true)}
                      >
                        Low priority sm blocked
                      </KisshtButton>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
              <Grid item xs={2} className={classes.root}>
                {process.env.REACT_APP_STATUS === 'Test' && <Alert variant="outlined" severity="info" icon={<InfoIcon />}>{process.env.REACT_APP_STATUS} Mode</Alert>}

              </Grid>
              <Grid item xs={2}>
                <RightSideDrawer />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <KisshtDialog
        maxWidth="sm"
        title="Soft Delete"
        open={model === true}
        handleClose={setModel}
      >
        <SoftDelete handleClose={setModel} />
      </KisshtDialog>
      <KisshtDialog
        maxWidth="sm"
        title="Low Priority SM Blocked"
        open={smBlockedModal === true}
        handleClose={setsmBlockedModal}
      >
        <Lowprioritysmblocked handleClose={setsmBlockedModal} />
      </KisshtDialog>
    </>
  );
};
