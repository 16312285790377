import DashboardIcon from "assets/svg/sidebar/dashboard.svg";

import TestingIcon from "assets/svg/sidebar/learning.svg";
import UsersIcon from "assets/svg/sidebar/users.svg";
import noun_report from "assets/svg/sidebar/noun_report.svg";
import settings from "assets/svg/sidebar/settings.svg";
import loans from "assets/svg/sidebar/loans.svg";
import agents from "assets/svg/sidebar/agents.svg";
import upload from "assets/svg/sidebar/upload.svg";
import snooze from "assets/svg/sidebar/snooze.svg";
import report from "assets/svg/sidebar/report.svg";
import email from "assets/svg/sidebar/email.svg";
import voucher from "assets/svg/sidebar/voucher.svg";
import merchant from "assets/svg/sidebar/merchant.svg";
import menu from "assets/svg/sidebar/menu.svg";
import restore from 'assets/svg/sidebar/restore.svg';
// var SideBariconWidth=24;
export const typeConstant = {
  MORATORIUM: "moratorium",
  FORECLOSURE: "foreclosure",
  DUES_WAIVER: "due-waiver",
};
export const ContentCardSize = {
  maxWidth: "93vw",
};

export const DrawerSize = {
  width: 250,
  closeWidth: 96,
};

// excel upload config
export const excelUpload = {
  rowLimit: 5000,
};

// Email Regex
export const EMAIL_REG_EXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NUMBER_REG_EXP = /^[0-9]*$/;
export const NUMBER_DECIMAL_REG_EXP = /^[0-9]\d*(\.\d*)?$/;
export const ONLY_STRING_REG_EXP = /^[A-Za-z]*$/;
export const ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9]*$/;
export const IFSCREG_EXP = /[A-Za-z]{4}[0][A-Za-z0-9]{6}/;
export const ALPHA_NUMERIC_UNDERSCORE_REGEX = /^[A-Za-z0-9_]*$/;

// Configuration of toast popup
export const toastConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "colored",
};

export const USER_API_URLS = {
  ssoAuth: "/api/v1/subusers/sso/auth/google",
  sendOTP: "/api/v1/subusers/send-otp",
  logIn: "/api/v1/subusers/login",
  subUser: "/api/v1/subuser",
  users: "/api/v1/users",
  getOTP: "/api/v1/users/change-mobile-otp",
  getOutStandingDetails: "/api/v1/users/outstanding-details",
  hiat_callverification: "/api/v1/dh-verification",
  hiatus_Caller: "/api/v1/dh-verification/caller",
  hiatus_Underwriter: "/api/v1/dh-verification/underwriter",
  hiatus_doc_caller: "/api/v1/dh-verification/doc-caller",
  engagement: "/api/v1/engagement",
  documentApprove: "/api/v1/users/dh-verification/approve-document",
  documentReject: "/api/v1/users/dh-verification/reject-document",
  downloadInvoice: "/api/v1/users/gst-invoice",
  reset_aggregator: "/api/v1/users/account-aggregator/reset-verification",
};

export const DH_API_URLS = {
  dhstatus: "/api/v1/users/update-dh-verification-status",
  dhaddress: "/api/v1/users/addresses",
  dhMarkdisposition: "api/v1/users/add-disposition",
  viewdisposition: "/api/v1/users",
  dhrejectcase: "/api/v1/users/dh-verification-status/rejected",
  dhapprovecase: "api/v1/users/dh-verification-status/approved",
  dhsendtocaller: "api/v1/users/dh-verification-status/doc_pending",
  dhsendtounderwriter: "/api/v1/users/dh-verification-status/pending_uw",
  dhdocumentType: "/api/v1/users/dh-verification/document-types",
  documentUpload: "/api/v1/users",
  dhdownload: "/api/v1/dh-verification/download/report-data",
  dispositionOption: "/api/v1/users/dh-verification/disposition-categories",
  documentRejectDropdown: "/api/v1/users/dh-verification/rework-reason-list",
  dispositionRejection: "/api/v1/users/dh-verification/rejection-categories",
  questionnarie: "/api/v1/users/dh-verification/questionnaire-list",
  questionnariecreate:
    "/api/v1/users/dh-verification/questionnaire/submit-response",
  dhMakeDecision: "api/v1/users/dh-verification/make-decision",
  blockSubuser: "/api/v1/dh-verification/blacklisted-subusers"
};

export const PAYMENT_API = {
  listing: "/api/v1/payments/list",
  view: "/api/v1/payments",
  viewMapping: "/api/v1/loans/mapping",
  verify: "/api/v1/payments/verify-payment",
  applyPayment: "/api/v1/payments/apply-payment",
  createPromotionalPayment: "/api/v1/payments/promotional",
  createPayment: "/api/v1/payments/create-paid",
  checkUtr: "/api/v1/payments/check-utr",
  bank: "/api/v1/banks",
  operatingbank: "/api/v1/operating-bank-accounts",
  paymentStatistics: "/api/v1/statistics/payments/hourly-basis-present-day",
  paymentSuccessRate: "/api/v1/statistics/payments/success-rate-statistics",
  uniqueDescriptor: "/api/v1/payments/unique-descriptor",
  financierpaymentaccount: "/api/v1/loans/get-active-financiers-list",


};

export const REFUND_API = {
  listing: "/api/v1/refunds/list",
};

export const SUBUSER_API = {
  byName: "/api/v1/subusers",
  roles: "/api/v1/subusers/roles",
  allPermissions: "/api/v1/all-permissions",
};

export const LOAN_API = {
  listing: "/api/v1/loans",
  loanOutstanding: "/api/v1/loans",
  dues: "/api/v1/dues",
  lmsMapping: "/api/v1/loans/lms-mapping",
  paymentReverse: "/api/v1/loans/payment-reverse",
  duesListing: "/api/v1/loans/dues-payment-mapping",
  duesPost: "/api/v1/loans/dues-payment-mapping-reverse",
  dmAccess: "/api/v1/subusers/dm-details",
};
export const SNOOZE_API = {
  allowed: "api/v1/snooze/allowed-users",
  blocked: "api/v1/snooze/blocked-users",
  deltedAllowedSnoozed: "api/v1/snooz/allowed-users/loans/",
};

export const TRANSACTION_API = {
  listing: "/api/v1/transactions",
  bankByIfsc: "/api/v1/banks/branches/ifsc",
  retryDisbursement: "/api/v1/disbursal/retry-disbursement/",
  disbursalInfo: "/api/v1/disbursal/info/",
  bbpsListing: "/api/v1/bbps/user-transaction-mis",
  addon: "/api/v1/addon",
  merchantListing: "api/v1/merchants",
  mapNach: "api/v1/nach/map",
  verify_bank: "api/v1/disbursal/source-beneficiary-verification",
  doc_Bank: "/api/v1/users/transaction",

};

export const COLLECTION_API = {
  listing: "/api/v1/collections",
};
export const CONFIGURATION_API = {
  listing: "api/v1/configurations",
  custom_config_list: "api/v1/configurations/custom",
};
export const VOUCHER_API = {
  listing: "api/v1/disbursal/voucher-list",
};
export const MERCHANT_API = {
  listing: "/api/v1/merchants",
  settlementList: "/api/v1/disbursal/merchant/merchant-settlements-listing",
  markSettlement:
    "/api/v1/disbursal/merchant/merchant-settlements-marked-settled",
  adjustSettlement:
    "/api/v1/disbursal/merchant/merchant-settlements-marked-adjust",
  totalRecord: "/api/v1/disbursal/merchant/merchant-settlements-records-count",
};
export const DISBURSE_API = {
  autoDisbursement: "api/v1/disbursal/autodisbursements",
  outbound_paument: "api/v1/disbursal/outbound-payments",
  statistics: "api/v1/disbursal/disbursement-statistics",
  voucher_stats: "api/v1/disbursal/voucher-disbursal-statistics",
  ift: "api/v1/disbursal/ift",
  iftEnquiry: "/enquiry",
  iftApproveTrans: "/approve-transaction",
  disbursal_accounts: "api/v1/disbursal/financier-disbursal-accounts",
  bank_mid_list: "api/v1/disbursal/bank-mid-list",
  bank_mid_balance: "api/v1/disbursal/bank-mid-balance",
  iftValidUsers: "api/v1/disbursal/ift/approver-list",
  update_outbound: "api/v1/disbursal/outbound-payment",
  sourceDestination_accounts: "api/v1/disbursal/ift/ift-bank-details",
  add_source_accounts: "api/v1/disbursal/ift/add-source-account",
  update_source_accounts: "api/v1/disbursal/ift/update-source-details",
  add_destination_accounts: "api/v1/disbursal/ift/add-destination-account",
  update_destination_accounts:
    "api/v1/disbursal/ift/update-destination-details",
  case_mark_failed: "api/v1/disbursal/ift/mark-ift-transaction-failed",
  bene_verification: "api/v1/disbursal/ift/bene-verification",
  disCofigList: "api/v1/disbursal/configuration-list",
  disCofigUpdate: "api/v1/disbursal/configuration",
  account_statements: "api/v1/disbursal/statements",
  settlement_refund_rewards: "api/v1/disbursal/refund-reward/settlement-list",
  outbound_refund_rewards: "api/v1/disbursal/refund-reward/outbound-list",
  update_status_outbound_refund_reward: "api/v1/disbursal/refund-reward/bulk-outbound-refund-reward/update-status",
  update_status_settlement_refund_reward: "api/v1/disbursal/refund-reward/bulk-settlement-refund-reward/update-status",
  topuploan_settlement: "api/v1/disbursal/top-up/settlement-list",
  topuploan_outbound: "api/v1/disbursal/top-up/outbound-list",
  update_topup_loan_settlement_status: "/api/v1/disbursal/top-up/bulk-settlement-top-up/update-status",
  update_topup_loan_outbound_status: "/api/v1/disbursal/top-up/bulk-outbound-top-up/update-status",
  iftBankList: "api/v1/disbursal/ift/ift-bank-list",
};

export const DIGI_REPORT_API = {
  addReport: "/reports/",
  getReportNames: "/offline_reports/list",
  getReportStatusKeys: "/reports/status_list",
  getUserEmails: "/users/",
};
export const Financier_FileUpload_API = {
  upload_listing: "/api/v1/disbursal/offbook",
};

export const KFS_MASTER_API = {
  getKFSFees: "api/v1/kfs/fetch-fees",
  createKFSFees: "api/v1/kfs/create-fees",
  updateKFSFees: "api/v1/kfs/update-fees",
  getKFStatements: "api/v1/kfs/fetch-statements",
  createKFStatements: "api/v1/kfs/create-statement",
  updateKFStatements: "api/v1/kfs/update-statement",
  getKFSConfig: "api/v1/kfs/fetch-statement-mapping",
  createKFSConfig: "api/v1/kfs/create-statement-mapping",
  updateKFSConfig: "api/v1/kfs/update-statement-mapping",
  getKFSMapping: "api/v1/kfs/fetch-fee-mapping",
  createKFSMapping: "api/v1/kfs/create-fee-mapping",
  updateKFSMapping: "api/v1/kfs/update-fee-mapping",
  getFinancier: "api/v1/loans/get-active-financiers-list",
};

export const REPORT_API = {
  listing: "/api/v1/reports/listing",
  createReport: "/api/v1/reports/create",
  viewReport: "/api/v1/reports/view",
  updateReport: "/api/v1/reports/update",
  downloadReport: "/api/v1/reports/download",
  uploadReportFile: "/api/v1/reports/upload-file",
};

export const MID_MASTER_API = {
  getPartnerList: "/api/v1/disbursal/partner-list",
  createMid: "/api/v1/disbursal/create-mid",
  products: "/api/v1/disbursal/products?service=disbursal",
  financiers: "/api/v1/disbursal/financiers",
  paymentmode: "/api/v1/disbursal/payment-modes?service=disbursal",
  midBase: "/api/v1/disbursal/mids?mode=",
  midBaseDetails: "/api/v1/disbursal/product-financier-mid-mapping",
  createMappingMID: "/api/v1/disbursal/product-financier-mid-mapping",
  mappingList: "/api/v1/disbursal/mid-management",
  updateMappingStatus: "/api/v1/disbursal/product-financier-mid-mapping/toggle",
};

export const ROUTE_NAME = {
  payment: "payment",
  loan: "loan",
  user: "user",
  "dh-verification": "dh-verification",
  dhverification: "dh-verification",
  testing: "testing",
  role: "role",
  refund: "refund",
  dues: "dues",
  nach: "nach",
  statistics: "statistics",
  merchant: "merchant",
  transaction: "transaction",
  fileUpload: "fileUpload",
  deposit: "deposit",
  subuserPayments: "subuserPayment",
  collections: "collections",
  configuration: "configuration",
  customConfig: "customConfig",
  snoozeAllowed: "snoozeAllowed",
  snoozeBlocked: "snoozeBlocked",
  email_configuration: "email_configuration",
  reports: "reports",
  digiReport: "digiDataReport",
  voucher: "voucher",
  Disbursal: "Disbursal",
  Disbursement: "Disbursement",
  addon: "addon",
  offer: "offer",
  line: "line",
  business_product_variants: "business_product_variants",
  all: "all",
  financier: "financier",
  "kfs-fee-master": "kfs",
  "bank-accounts": "bank-accounts",
  subusermanagementList: "subuser_management",
  report: "reports",
  userDigitalFootprint: "user-digital-footprint",
  masterBank: "master_bank",
  "subuser-registration": "subuser-registration",
  actionlogs: "adminActionLogs",
  offerunit: "offer-unit",
  promoOffer: "promoOffer",
  financiers: "financiers",
  koino: "koino",
  grid: "grid",
  gbgrid: "gbgrid",
  midManagement: "midManagement",
};

export const PERMISSIONS = {
  dashboard: { list: "list", details: "details" },
  koino: {
    upload_transit_user: "upload_transit_user",
  },
  payment: {
    list: "list",
    details: "details",
    loan_outstanding_details: "loan_outstanding_details",
    efa_outstanding_details: "efa_outstanding_details",
    user_outstanding_details: "user_outstanding_details",
    add_promotional_payment: "add_promotional_payment",
    change_to_loan_payment: "change_to_loan_payment",
    add_paid_payment: "add_paid_payment",
    verify_payment: "verify_payment",
    apply_payment: "apply_payment",
    remove_payment: "remove_payment",
    change_payment_reason: "change_payment_reason",
    capture_razorpay_payment: "capture_razorpay_payment",
    check_utr: "check_utr",
    mark_unpaid: "mark_unpaid",
    generate_link: "generate_link",
    report_fraud_user: "report_fraud_user",
    dues_mapping_reverse: "dues_mapping_reverse",
    unique_descriptor: "unique_descriptor",
  },
  user: {
    bank_accounts: "bank_accounts",
    list: "list",
    details: "details",
    change_mobile_otp: "change_mobile_otp",
    change_mobile_number: "change_mobile_number",
    add_bank_accounts: "add_bank_accounts",
    comments: "comments",
    transactions: "transactions",
    userdocuments: "userdocuments",
    vcip_additional_data: "vcip_additional_data",
    send_flipkart_payment_link: "send_flipkart_payment_link",
    search_by_name: "search_by_name",
    update: "update",
    temporaryblocklist: "temporaryblocklist",
    send_verification_sms: "send_verification_sms",
    dh_add_disposition: "add_disposition",
    dh_get_disposition: "get_disposition",
    dh_rejected: "dh_rejected",
    dh_approve_for_disbursal: "dh_approved",
    dh_send_to_underwriting: "dh_pending_uw",
    dh_send_to_caller: "dh_doc_pending",
    dh_add_user_address: "add_user_address",
    dh_upload_document: "upload_document",
    loan_payment_listing: "loan_payment_listing",
    dh_doc_rejected: "dh_doc_rejected",
    dh_doc_approved: "dh_doc_approved",
    account_aggregator: "account_aggregator",
    reset_account_aggregator: "reset_account_aggregator",
    dh_make_decision: "dh_make_decision",
    update: "update",
    questionnaire_list: "questionnaire_list",
    dh_make_decision: "dh_make_decision",
    audit_trail_events: "audit_trail_events",
    audit_trail_event_details: "audit_trail_event_details",
    consent_details: "consent_details",
    sendVideoKycLink: "send_video_kyc_link",
    customer_search: "customer_search",
    reset_playstore_user_otp: "reset_playstore_user_otp",
    invoice_list: "invoice_list",
    payment_invoice_list: "payment_invoice_list",
    loan_invoice_list: "loan_invoice_list",
    download_invoice: "download_invoice",
    send_invoice: "send_invoice",
    pan_linked_details:"pan_linked_details"
  },
  "dh-verification": {
    getList: "getList",
    getListCaller: "getListCaller",
    getListUnderwriter: "getListUnderwriter",
    getListDocCaller: "getListDocCaller",
    download_report_data: "download_report_data",
    update_dh_status: "update_dh_status"
  },

  refund: {
    list: "list",
    details: "details",
    create: "create",
    marked_refunded: "marked_refunded",
    updateRefund: "updateRefund",
  },
  dues: {
    list: "list",
    details: "details",
    dues_waive_off: "dues_waive_off",
    undo_dues_waive_off: "undo_dues_waive_off",
  },
  loan: {
    list: "list",
    cancel: "cancel",
    mapping_data: "mapping_data",
    lms_mapping_data: "lms_mapping_data",
    sync: "sync",
    due_waiver_activate: "due_waiver_activate",
    due_waiver_deactivate: "due_waiver_deactivate",
    due_waiver_send_payment_link: "due_waiver_send_payment_link",
    moratorium_details: "moratorium_details",
    moratorium_apply: "moratorium_apply",
    moratorium_activate: "moratorium_activate",
    moratorium_payment_link: "moratorium_payment_link",
    moratorium_deactivate: "moratorium_deactivate",
    calculate_foreclosure: "calculate_foreclosure",
    apply_foreclosure_payment: "apply_foreclosure_payment",
    send_foreclosure_link: "send_foreclosure_link",
    settlement_details: "settlement_details",
    settlement_activate: "settlement_activate",
    settlement_deactivate: "settlement_deactivate",
    emi_holiday_activate: "emi_holiday_activate",
    emi_holiday_deactivate: "emi_holiday_deactivate",
    restructure_activate: "restructure_activate",
    restructure_deactivate: "restructure_deactivate",
    due_waiver_details: "due_waiver_details",
    details: "details",
    send_campaign_payment_link: "send_campaign_payment_link",
    send_user_payment_link: "send_user_payment_link",
    send_foreclosure_payment_link: "send_foreclosure_payment_link",
    send_moratorium_payment_link: "send_moratorium_payment_link",
    send_due_waiver_payment_link: "send_due_waiver_payment_link",
    send_emi_holiday_payment_link: "send_emi_holiday_payment_link",
    send_restructure_payment_link: "send_restructure_payment_link",
    generate_or_send_noc: "generate_or_send_noc",
    disallow_dues_waiver: "disallow_dues_waiver",
    generate_soa: "generate_soa",
    pf_gst_invoice: "pf_gst_invoice",
    if_gst_invoice: "if_gst_invoice",
    user_loan_details: "user_loan_details",
    hypothecation_details: "hypothecation_details",
    penalty_details: "penalty_details",
    welcome_letter: "welcome-letter",
  },
  testing: "testing",
  role: { list: "list", update: "update" },
  nach: {
    list: "list",
    details: "details",
    present: "present",
    nachList: "nachList",
    nachDetails: "nachDetails",
    summary: "summary",
    nachPaymentList: "nachPaymentList",
    cancel:"cancel",
  },
  statistics: {
    hourly_basis_payments_count: "hourly_basis_payments_count",
  },
  transaction: {
    list: "list",
    details: "details",
    transactionTab: "transactionTab",
    demographicsTab: "demographicsTab",
    productMerchantDetailsTab: "productMerchantDetailsTab",
    incomeDataTab: "incomeDataTab",
    nachDetailsTab: "nachDetailsTab",
    documentDocketTab: "documentDocketTab",
    actionsTab: "actionsTab",
    comments: "comments",
    creditFraudTab: "creditFraudTab",
    quickActions: "quickActions",
    softDelete: "softDelete",
    downloadDocket: "downloadDocket",
    downloadNach: "downloadNach",
    addunifiedcomment: "addunifiedcomments",
    cibilDataTab: "cibilDataTab",
    welcomeLetter: "welcomeLetter",
    sanctionLetter: "sanctionLetter",
    insuranceLetter: "insuranceLetter",
    Cancelpolicy: "Cancelpolicy",
    createMandateLink: "createMandateLink",
    customerDisbursalBankDetails: "customerDisbursalBankDetails",
    createSettlement: "createSettlement",
    inprocessNachDetails:"inprocessNachDetails"
  },
  deposit: {
    list: "list",
    details: "details",
    create: "create",
    verify: "verify",
    cancel: "cancel",
    add_payment: "add_payment",
    remove_payment: "remove_payment",
  },
  subuserPayment: {
    list: "list",
    details: "details",
    verify: "verify",
  },
  collections: {
    lowPriorityBulkUpload: "lowPriorityBulkUpload",
    addUnifiedComments: "addUnifiedComments",
    "kaleyra-call": "kaleyra-call",
  },
  configuration: {
    list: "list",
    view: "view",
    update: "update",
    create: "create",
  },
  customConfig: { list: "list", update: "update" },
  fileUpload: {
    list: "list",
    view: "details",
    uploadFile: "uploadFile",
    responseFile: "responseFile",
    stats: "stats",
    intruptBulkUpload: "intruptBulkUpload",
    fileList: "fileList",
  },
  snoozeAllowed: {
    list: "list",
    create: "create",
    update: "update",
    remove: "remove",
  },
  snoozeBlocked: {
    list: "list",
    create: "create",
    update: "update",
    remove: "remove",
  },
  email_configuration: {
    list: "list",
    create: "create",
    update: "update",
  },
  digi_report: {
    list: "list",
    create: "create",
  },
  addon: {
    list: "list",
    details: "details",
  },
  offer: {
    list: "list",
    add: "add",
    details: "details",
  },
  "offer-unit": {
    list: "list",
    basketList: "basketList"
  },
  line: {
    list: "list",
    details: "details",
    "transaction-history": "transaction-history",
    statements: "statements",
    "generate-send-statement": "generate-send-statement",
  },
  merchant: {
    list: "list",
    details: "details",
  },
  business_product_variants: {
    create: "create",
    update: "update",
    list: "list",
  },
  Disbursement: {
    statistics: "statistics",
    voucher_statistics: "voucher_statistics",
  },
  Disbursal: {
    list: "list",
    retry_disbursement: "retry_disbursement",
    disbursement_details: "disbursement_details",
    ift_list: "ift_list",
    ift_transfer: "ift_transfer",
    ift_enquiry: "ift_enquiry",
    bank_mid_list: "bank_mid_list",
    bank_mid_balance: "bank_mid_balance",
    financier_account_list: "financier_account_list",
    financier_account_create: "financier_account_create",
    financier_account_update: "financier_account_update",
    auto_disbursements: "auto_disbursements",
    list_outbound: "list_outbound",
    disbursement_statistics: "disbursement_statistics",
    settlement_detail: "settlement_detail",
    voucher_list: "voucher_list",
    voucher_disbursal_statistics: "voucher_disbursal_statistics",
    source_beneficiary_verification: "source_beneficiary_verification",
    update_outbound: "update_outbound",
    ift_pre_process: "ift_pre_process",
    approve_transaction: "approve_transaction",
    approver_list: "approver_list",
    merchant_settlements_listing: "merchant-settlements-listing",
    merchant_settlements_records_count: "merchant-settlements-records-count",
    merchant_settlements_marked_settled: "merchant-settlements-marked-settled",
    merchant_settlements_marked_adjust: "merchant-settlements-marked-adjust",
    configuration_list: "configuration_list",
    configuration_edit: "configuration_edit",
    view_bank_statement: "view_bank_statement",
    refund_reward_settlement_list: "refund_reward_settlement_list",
    refund_reward_outbound_list: "refund_reward_outbound_list",
    update_refund_reward_outbound_status: "update_refund_reward_outbound_status",
    update_refund_reward_settlement_status: "update_refund_reward_settlement_status",
    top_up_settlement_list: "top_up_settlement_list",
    top_up_outbound_list: "top_up_outbound_list",
    update_top_up_outbound_status: "update_top_up_outbound_status",
    update_top_up_settlement_status: "update_top_up_settlement_status",
  },
  kfs: {
    feesList: "feesList",
    createFees: "createFees",
    updateFees: "updateFees",
    kfsList: "kfsList",
    createKfs: "createKfs",
    updateKfs: "updateKfs",
    feeMappingList: "feeMappingList",
    createFeeMapping: "createFeeMapping",
    updateFeeMapping: "updateFeeMapping",
    kfsMappingList: "kfsMappingList",
    createKfsMapping: "createKfsMapping",
    updateKfsMapping: "updateKfsMapping",
  },
  all: "all",
  bbps: {
    user_transaction_mis: "user_transaction_mis",
  },
  offbook: {
    utr_payments_mapping_bulk_upload: "utr_payments_mapping_bulk_upload",
  },
  "bank-accounts": {
    list: "list",
  },
  subuser_management: {
    get_list: "get_listing",
    create_subuser: "create_subuser",
    update_subuser: "update_subuser",
    unlink_device_info: "unlink_device_info",
    changes_subuser_status: "changes_subuser_status"
  },
  "master-data": {
    list: "list",
  },
  reports: {
    list: "list",
    create: "create",
    update: "update",
    view: "view",
    download: "download",
    filter: "filter",
    upload_file: "upload_file",
  },
  "user-digital-footprint": {
    request_list: "delete_request_list",
    mark_completed: "delete_request",
  },
  masterBank: {
    list: "list_all",
    update: "edit",
    create: "add",
  },
  "subuser-registration": {
    getList: "getList",
    statusUpdate: "statusUpdate"
  },
  adminActionLogs: {
    listing: "listing"
  },
  financiers: {
    list: "list",
    details: "details",
    getPaymentAccounts: "getPaymentAccounts",
    addPaymentAccounts: "addPaymentAccounts",
  },
  "grid": {
    getFresh: "getfresh",
  },
  midManagement: {
    list: "list",
    midList: "midList",
    createMid: "createMid",
    updateMid: "updateMid",
    updateMapping: "updateMapping",
    updatePartnerMidDetails: "updatePartnerMidDetails",
    details: "details",
    updatePartner: "updatePartner",
    createPartner: "createPartner",
    midDetails: "midDetails",
    partnerDetails: "partnerDetails",
    partnerList: "partnerList",
    paymentModes: "paymentModes",
    getFields: "getFields",
    products: "products",
    financiers: "financiers",
    addProductFinancierMidMapping: "addProductFinancierMidMapping",
    productFinancierMidMappingList: "productFinancierMidMappingList",
    midsList: "midsList",
    getProductFinancierMidMappingDetail: "getProductFinancierMidMappingDetail",
  },

};

export const ROUTES_VAR = {
  signInOtp: "/sign-in-otp",
  sign: "/sign-in",
  dashboard: "/",
  loans: "/loans",
  loan: "/loans/:id",
  users: "/user/users",
  userSearch: "/user/search/list",
  dhverification: "/dh-verification",
  dhverificationlist: "/dh-verification/listing",
  dhverification_call: "/dh-verification/caller",
  dhverification_underwriter: "/dh-verification/underwriter",
  dhverification_doc: "/dh-verification/doc-caller",
  // dhverifi: "/dhverification/list",
  user: "/user/users/:id",
  merchants: "/merchants",
  merchantView: "/merchants/:id",
  transactions: "/transactions",
  transaction: "/transactions/:id",
  documentViewer: "/document-viewer",
  testing: "/testing-panel",
  nach: "/nach",
  nachDetail: "/nach/:id",
  mandate: "/mandate",
  mandateDetail: "/mandate/:id",
  docket_tracking_panel: {
    link: "/docket-tracking-panel",
    external_docket: "/docket-tracking-panel/external-docket",
  },
  adminGrp: "/admin",
  roles: "/admin/roles",
  role: "/admin/roles/:id",
  payments: "/payments",
  refund: "/refund",
  refund_view: "/refund/:id",
  check_utr: "/utr",
  payment_view: "/payments/:id",
  statistics: "/statistics",
  fileUpload: "/uploads",
  whatsappReminder: "/whatsapp-reminder",
  deposits: "/deposits",
  deposit_view: "/deposits/:id",
  subuserPayments: "/subuser_payments",
  subuserPayments_view: "/subuser_payments/:id",
  configuration: "/configuration",
  customConfiguration: "/custom-configuration",
  snoozed_allowed: "/snoozed-allowed",
  snoozed_blocked: "/snoozed-blocked",
  reports: "/reports",
  vouchers: "/vouchers",
  email: "/email-config",
  digi_report: "/digi-data-report",
  disbursal: "/disbursal",
  autoDisbursal: "/disbursal/auto-disburse",
  outboundPayments: "/disbursal/outbound-payments",
  disburseStatistics: "/disbursal/statistics",
  ift: "/disbursal/ift",
  disburssalAccounts: "/disbursal/accounts",
  bbpsListing: "/bbps",
  addon: "/addon",
  offer: "/offers",
  offerView: "/offers/:id",
  bnpl: "/bnpl",
  bnplView: "/bnpl/:id",
  productConfiguration: "/product-configuration",
  financier: "/financier",
  accountBalance: "/disbursal/account-balance",
  kfsFeeMaster: "/kfs-fee-master",
  sourceDestinationAccounts: "/disbursal/source-destination-accounts",
  nachLp: "/nach/lotuspay",
  nachRp: "/nach/razorpay",
  merchantSettlement: "/disbursal/settlement",
  usersBankDetails: "/user/user-bank-details",
  userGrp: "/user",
  subusermanagementList: "/admin/subuser-management",
  report: "/report",
  userDigitalFootprint: "/user-digital-footprint",
  banks: "/master-data/banks",
  pendingsubuser: "/admin/subuser_management/pendinglist",
  disburdalConfigurations: "/disbursal/configurations",
  accountStatements: "/disbursal/account-statements",
  customerDeatilsView: "/collection-crn",
  settlementRefundRewards: "/disbursal/settlement-refund-rewards",
  actionLogs: "/other/actionlogs",
  outboundRefundRewards: "/disbursal/outbound-refund-rewards",
  financiers: "/financiers",
  uniqueDescriptor: "/unique_descriptor",
  customerSupport: "/customersupport",
  topupLoanSettlement: "/disbursal/topuploan-settlement",
  topupLoanOutbound: "/disbursal/topuploan-outbound",
  koinoDetails: "/koino",
  koinoList: "/koino/list",
  loanMatrix: "/promo/offer/loan-matrix",
  offerlisting: "/promo/offer/unit/listing",
  basketListing: "/promo/offer/basket/listing",
  promOffer: "/promo",
  actionLogs: "/other/actionlogs",
  midManagement: "/disbursal/mid-management",




  // payment: {
  //   link: "/payment-panel",
  //   payment: "/payment-panel/payment",
  //   payment_view: "/payment-panel/payment/view/:id",
  //   razorpay: "/payment-panel/razorpay",
  //   payu: "/payment-panel/payu",
  //   paytm: "/payment-panel/paytm",
  // },
};

// eslint-disable-next-line no-sparse-arrays
export const SIDEBAR_LINKS = [
  {
    text: "Dashboard",
    link: ROUTES_VAR.dashboard,
    icon: DashboardIcon,
    route: "/",
  },
  {
    text: "Statistics",
    link: ROUTES_VAR.statistics,
    icon: noun_report,
    route: ROUTE_NAME.statistics,
  },
  {
    text: "Customer Support",
    link: ROUTES_VAR.customerSupport,
    icon: noun_report,
    route: ROUTE_NAME.user,
    permission: PERMISSIONS.user.customer_search,
  },
  {
    text: "Loans",
    link: ROUTES_VAR.loans,
    icon: loans,
    route: ROUTE_NAME.loan,
    //permission: PERMISSIONS.loan.list,
  },
  {
    text: "Users",
    link: ROUTES_VAR.userGrp,
    icon: UsersIcon,
    route: ROUTE_NAME.user,
    child: [
      {
        text: "Users",
        link: ROUTES_VAR.users,
        icon: UsersIcon,
        route: ROUTE_NAME.user,
        //permission: PERMISSIONS.user.list,
      },
      {
        text: "Users Search",
        link: ROUTES_VAR.userSearch,
        icon: UsersIcon,
        route: ROUTE_NAME.user,
        //permission: PERMISSIONS.user.search_by_name,
      },
      {
        text: "Users Bank Details",
        link: ROUTES_VAR.usersBankDetails,
        icon: noun_report,
        route: ROUTE_NAME["bank-accounts"],
        permission: PERMISSIONS["bank-accounts"].list,
      },
    ],
  },
  {
    text: "DH Verification",
    link: ROUTES_VAR.dhverification,
    icon: UsersIcon,
    route: ROUTE_NAME["dh-verification"],
    child: [
      {
        text: "DH Listing",
        link: ROUTES_VAR.dhverificationlist,
        icon: UsersIcon,
        route: ROUTE_NAME["dh-verification"],
        permission: PERMISSIONS["dh-verification"].getList,
      },
      {
        text: "DH Caller",
        link: ROUTES_VAR.dhverification_call,
        icon: UsersIcon,
        route: ROUTE_NAME["dh-verification"],
        permission: PERMISSIONS["dh-verification"].getListCaller,
      },
      {
        text: "DH Underwriter",
        link: ROUTES_VAR.dhverification_underwriter,
        icon: UsersIcon,
        route: ROUTE_NAME["dh-verification"],
        permission: PERMISSIONS["dh-verification"].getListUnderwriter,
      },
      {
        text: "DH DOC Caller",
        link: ROUTES_VAR.dhverification_doc,
        icon: UsersIcon,
        route: ROUTE_NAME["dh-verification"],
        permission: PERMISSIONS["dh-verification"].getListDocCaller,
      },
    ],
  },
  {
    text: "Transactions",
    link: ROUTES_VAR.transactions,
    icon: noun_report,
    route: ROUTE_NAME.transaction,
    //permission: PERMISSIONS.transaction.list,
  },
  {
    text: "Merchants",
    link: ROUTES_VAR.merchants,
    icon: noun_report,
    route: ROUTE_NAME.merchant,
    //permission: PERMISSIONS.merchant.list,
  },
  {
    text: "BBPS Transactions",
    link: ROUTES_VAR.bbpsListing,
    icon: noun_report,
    route: ROUTE_NAME.all,
    //permission: PERMISSIONS.bbps.user_transaction_mis,
  },
  {
    text: "BNPL Transactions",
    link: ROUTES_VAR.bnpl,
    icon: noun_report,
    route: ROUTE_NAME.line,
    //permission: PERMISSIONS.line.list,
  },
  {
    text: "Addon Transaction",
    link: ROUTES_VAR.addon,
    icon: noun_report,
    //permission: PERMISSIONS.addon.list,
    route: ROUTE_NAME.addon,
  },
  {
    text: "Action Logs",
    link: ROUTES_VAR.actionLogs,
    icon: restore,
    route: ROUTE_NAME.actionlogs,
    permission: PERMISSIONS.adminActionLogs.listing,
  },
  {
    text: "Payments",
    link: ROUTES_VAR.payments,
    icon: noun_report,
    route: ROUTE_NAME.payment,
    child: [
      {
        text: "Payments",
        link: ROUTES_VAR.payments,
        icon: noun_report,
        route: ROUTE_NAME.payment,
        //permission: PERMISSIONS.payment.list,
      },
      {
        text: "Check UTR",
        link: ROUTES_VAR.check_utr,
        icon: noun_report,
        route: ROUTE_NAME.payment,
        //permission: PERMISSIONS.payment.check_utr,
      },
      {
        text: "Unique Descriptor",
        link: ROUTES_VAR.uniqueDescriptor,
        icon: noun_report,
        route: ROUTE_NAME.payment,
        permission: PERMISSIONS.payment.unique_descriptor,
      },
      {
        text: "Financier Payment Accounts",
        link: ROUTES_VAR.financiers,
        icon: noun_report,
        route: ROUTE_NAME.financiers,
        permission: PERMISSIONS["financiers"].getPaymentAccounts,
      },
    ],
  },
  {
    text: "Subuser payments",
    link: ROUTES_VAR.subuserPayments,
    icon: noun_report,
    route: ROUTE_NAME.subuserPayments,
    //permission: PERMISSIONS.subuserPayment.list,
  },
  {
    text: "Deposits",
    link: ROUTES_VAR.deposits,
    icon: loans,
    route: ROUTE_NAME.deposit,
    //permission: PERMISSIONS.deposit.list,
  },
  {
    text: "Refund",
    link: ROUTES_VAR.refund,
    icon: noun_report,
    route: ROUTE_NAME.refund,
    //permission: PERMISSIONS.refund.list,
  },
  {
    text: "Report",
    link: ROUTES_VAR.report,
    icon: noun_report,
    route: ROUTE_NAME.report,
    // permission: PERMISSIONS.reports.list,
  },
  {
    text: "Nach Presentation",
    link: ROUTES_VAR.nach,
    icon: agents,
    route: ROUTE_NAME.nach,
    child: [
      {
        text: "Lotuspay Nach",
        link: ROUTES_VAR.nachLp,
        icon: agents,
        route: ROUTE_NAME.nach,
        //permission: PERMISSIONS.nach.list,
      },
      {
        text: "Razorpay AutoDebit",
        link: ROUTES_VAR.nachRp,
        icon: agents,
        route: ROUTE_NAME.nach,
        //permission: PERMISSIONS.nach.list,
      },
    ],
  },
  {
    text: "Mandate",
    link: ROUTES_VAR.mandate,
    icon: agents,
    route: ROUTE_NAME.nach,
    //permission: PERMISSIONS.nach.nachList,
  },
  {
    text: "Offers",
    link: ROUTES_VAR.promOffer,
    icon: TestingIcon,
    route: ROUTE_NAME.offerunit,
    child: [

      {
        text: "Offers Listing",
        link: ROUTES_VAR.offerlisting,
        icon: noun_report,
        route: ROUTE_NAME.offerunit,
        permission: PERMISSIONS["offer-unit"].list,
      },
      {
        text: "Basket Listing",
        link: ROUTES_VAR.basketListing,
        icon: noun_report,
        route: ROUTE_NAME.offerunit,
        permission: PERMISSIONS["offer-unit"].basketList,
      },
      {
        text: "Loan Matrix",
        link: ROUTES_VAR.loanMatrix,
        icon: report,
        route: ROUTE_NAME.grid,
        permission: PERMISSIONS.grid.getFresh,
      }
    ],
  },
  {
    text: "Promo Offer",
    link: ROUTES_VAR.offer,
    icon: TestingIcon,
    route: ROUTE_NAME.offer,
    //permission: PERMISSIONS.offer.list,
  },
  {
    text: "Testing panel",
    link: ROUTES_VAR.testing,
    icon: TestingIcon,
    route: ROUTE_NAME.testing,
    //permission: PERMISSIONS.testing,
  },
  {
    text: "Admin Management",
    link: ROUTES_VAR.adminGrp,
    icon: menu,
    route: ROUTE_NAME.subusermanagementList,
    child: [
      {
        text: "Roles panel",
        link: ROUTES_VAR.roles,
        icon: agents,
        route: ROUTE_NAME.role,
        //permission: PERMISSIONS.role.list,
      },
      {
        text: "Subuser Management",
        link: ROUTES_VAR.subusermanagementList,
        icon: merchant,
        route: ROUTE_NAME.subusermanagementList,
        permission: PERMISSIONS.subuser_management.get_list,
      },
      {
        text: "Pending Employee Requests",
        link: ROUTES_VAR.pendingsubuser,
        icon: agents,
        route: ROUTE_NAME["subuser-registration"],
        // permission: PERMISSIONS["subuser-registration"].getList,
      },
    ],
  },

  {
    text: "File Uploads",
    link: ROUTES_VAR.fileUpload,
    icon: upload,
    route: ROUTE_NAME.fileUpload,
    //permission: PERMISSIONS.fileUpload.list,
  },
  {
    text: "Whatsapp Reminder",
    link: ROUTES_VAR.whatsappReminder,
    icon: upload,
    route: ROUTE_NAME.fileUpload,
    //permission: PERMISSIONS.fileUpload.list,
  },
  {
    text: "Configuration",
    link: ROUTES_VAR.configuration,
    icon: settings,
    route: ROUTE_NAME.configuration,
    //permission: PERMISSIONS.configuration.list,
  },
  {
    text: "Custom Configuration",
    link: ROUTES_VAR.customConfiguration,
    icon: settings,
    route: ROUTE_NAME.customConfig,
    //permission: PERMISSIONS.customConfig.list,
  },
  {
    text: "Product Configuration",
    link: ROUTES_VAR.productConfiguration,
    icon: settings,
    route: ROUTE_NAME.all,
    //permission: PERMISSIONS.customConfig.list,
  },
  {
    text: "Snoozed Allowed",
    link: ROUTES_VAR.snoozed_allowed,
    icon: snooze,
    route: ROUTE_NAME.snoozeAllowed,
    //permission: PERMISSIONS.snoozeAllowed.list,
  },
  {
    text: "Snoozed Blocked",
    link: ROUTES_VAR.snoozed_blocked,
    icon: snooze,
    route: ROUTE_NAME.snoozeBlocked,
    //permission: PERMISSIONS.snoozeBlocked.list,
  },
  {
    text: "Koino",
    link: ROUTES_VAR.koinoDetails,
    icon: settings,
    route: ROUTE_NAME.koino,
    child: [
      {
        text: "Koino",
        link: ROUTES_VAR.koinoList,
        icon: settings,
        route: ROUTE_NAME.koino,
        //permission: PERMISSIONS.koino.upload_transit_user,
      },
    ],
  },
  // {
  //   text: "Reports",
  //   link: ROUTES_VAR.reports,
  //   icon: report,
  //   route: ROUTE_NAME.reports,
  // },
  {
    text: "Vouchers",
    link: ROUTES_VAR.vouchers,
    icon: voucher,
    route: ROUTE_NAME.voucher,
    //permission: PERMISSIONS.Disbursal.voucher_list,
  },
  {
    text: "Digi Data Report",
    link: ROUTES_VAR.digi_report,
    icon: report,
    route: ROUTE_NAME.digiReport,
    //permission: PERMISSIONS.digi_report,
  },
  {
    text: "Email Configuration",
    link: ROUTES_VAR.email,
    icon: email,
    route: ROUTE_NAME.email_configuration,
    //permission: PERMISSIONS.email_configuration.list,
  },
  {
    text: "Disbursal",
    link: ROUTES_VAR.disbursal,
    icon: noun_report,
    route: ROUTE_NAME.Disbursal,
    //permission: PERMISSIONS.Disbursal.list,
    child: [
      {
        text: "Auto Disbursal",
        link: ROUTES_VAR.autoDisbursal,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        //permission: PERMISSIONS.Disbursal.auto_disbursements,
      },
      {
        text: "OutBound Payments",
        link: ROUTES_VAR.outboundPayments,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        //permission: PERMISSIONS.Disbursal.list_outbound,
      },
      {
        text: "Disburse Statistics",
        link: ROUTES_VAR.disburseStatistics,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        //permission: PERMISSIONS.Disbursal.disbursement_statistics,
      },
      {
        text: "Account Balance",
        link: ROUTES_VAR.accountBalance,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        //permission: PERMISSIONS.Disbursal.bank_mid_list,
      },
      {
        text: "Internal Fund transfer",
        link: ROUTES_VAR.ift,
        icon: noun_report,
        route: ROUTE_NAME.disbursal,
        //permission: PERMISSIONS.Disbursal.ift_list,
      },
      {
        text: "Disbursal Accounts",
        link: ROUTES_VAR.disburssalAccounts,
        icon: noun_report,
        route: ROUTE_NAME.disbursal,
        //permission: PERMISSIONS.Disbursal.financier_account_list,
      },
      {
        text: "Source-Destination Account",
        link: ROUTES_VAR.sourceDestinationAccounts,
        icon: noun_report,
        route: ROUTE_NAME.disbursal,
        ink: ROUTES_VAR.sourceDestinationAccounts,
        //permission: PERMISSIONS.Disbursal.financier_account_list,
      },
      {
        text: "Merchant Settlement",
        link: ROUTES_VAR.merchantSettlement,
        icon: noun_report,
        route: ROUTE_NAME.disbursal,
        ink: ROUTES_VAR.merchantSettlement,
        //permission: PERMISSIONS.Disbursal.list,
      },
      {
        text: "Disbrusal Configurations",
        link: ROUTES_VAR.disburdalConfigurations,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        permission: PERMISSIONS.Disbursal.configuration_list,
      },
      {
        text: "Account Statements",
        link: ROUTES_VAR.accountStatements,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        permission: PERMISSIONS.Disbursal.view_bank_statement,
      },
      {
        text: "Settlement Refund/Rewards",
        link: ROUTES_VAR.settlementRefundRewards,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        permission: PERMISSIONS.Disbursal.refund_reward_settlement_list,
      },
      {
        text: "Outbound Refund/Rewards",
        link: ROUTES_VAR.outboundRefundRewards,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        permission: PERMISSIONS.Disbursal.refund_reward_outbound_list,
      },
      {
        text: "TopupLoan Settlement",
        link: ROUTES_VAR.topupLoanSettlement,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        permission: PERMISSIONS.Disbursal.top_up_settlement_list,
      },
      {
        text: "TopupLoan Outbound",
        link: ROUTES_VAR.topupLoanOutbound,
        icon: noun_report,
        route: ROUTE_NAME.Disbursal,
        permission: PERMISSIONS.Disbursal.top_up_outbound_list,
      },
      {
      text: "MID Management",
      link: ROUTES_VAR.midManagement,
      icon: noun_report,
      route: ROUTE_NAME.midManagement,
      permission: PERMISSIONS.midManagement.midList,
      },
    ],
  },
  {
    text: "Financier",
    link: ROUTES_VAR.financier,
    icon: noun_report,
    route: ROUTE_NAME.financier,
    //permission: PERMISSIONS.offbook.utr_payments_mapping_bulk_upload,
  },
  {
    text: "KFS Fee Master",
    link: ROUTES_VAR.kfsFeeMaster,
    icon: loans,
    route: ROUTE_NAME["kfs-fee-master"],
    //permission: PERMISSIONS.kfs.feesList,
  },
  {
    text: "User Digital FootPrint",
    link: ROUTES_VAR.userDigitalFootprint,
    icon: noun_report,
    route: ROUTE_NAME.userDigitalFootprint,
    permission: PERMISSIONS["user-digital-footprint"].request_list,
  },
  {
    text: "Master Data",
    link: ROUTES_VAR.banks,
    icon: UsersIcon,
    route: ROUTE_NAME.masterBank,
    child: [
      {
        text: "Banks",
        link: ROUTES_VAR.banks,
        icon: UsersIcon,
        route: ROUTE_NAME.masterBank,
        permission: PERMISSIONS.masterBank.list,
      },
    ],
  },

  // {
  //   text: "Transactions",
  //   link: ROUTES_VAR.transactions,
  //   icon: DashboardIcon
  //     // <RefundIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[2], fontSize: 20 }}
  //     // />

  // },
  // {
  //   text: "Underwriting Panel",
  //   link: "/underwriting-panel",
  //   icon: AgentsIcon
  //     // <SettingsIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[3], fontSize: 20 }}
  //     // />

  // },
  // {
  //   text: "Pre Post Disbursal",
  //   link: "/pre-post-disbursal",
  //   icon: DashboardIcon
  //     // <SettingsIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[4], fontSize: 20 }}
  //     // />

  // },
  // {
  //   text: "Refund",
  //   link: "/refund",
  //   icon: DashboardIcon
  //     // <RefundIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[5], fontSize: 20 }}
  //     // />

  // },
  // {
  //   text: "CPV Panel",
  //   link: "/cpv-panel",
  //   icon: DashboardIcon
  //     // <RefundIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //     // />

  // },
  // // {
  // //   text: "NACH Registration Panel",
  // //   link: "/nach-registration-panel",
  // //   icon: DashboardIcon,
  // //     // <DocIcon
  // //     //   className="mx-auto"
  // //     //   style={{ color: SIDEBAR_COLORS[1], fontSize: 20 }}
  // //     // />

  // //   child: [
  // //     {
  // //       text: "NACH Registration Panel",
  // //       link: "/nach-registration-panel",
  // //       icon: ""
  // //     },
  // //     {
  // //       text: "Registration Batches",
  // //       link: "/nach-registration-panel/registration-batches",
  // //       icon: ""
  // //     }
  // //   ]
  // // },
  // // {
  // //   text: "Docket Tracking Panel",
  // //   link: "/docket-tracking-panel",
  // //   icon: DashboardIcon,
  // //     // <DocIcon
  // //     //   className="mx-auto"
  // //     //   style={{ color: SIDEBAR_COLORS[2], fontSize: 20 }}
  // //     // />

  // //   child: [
  // //     {
  // //       text: "External Docket",
  // //       link: "/docket-tracking-panel/external-docket",
  // //       icon: ""
  // //     },
  // //     {
  // //       text: "Internal Docket",
  // //       link: "/docket-tracking-internal-docket",
  // //       icon: ""
  // //     },
  // //     {
  // //       text: "Docket Pickup Process",
  // //       link: "/docket-tracking-panel/docket-pickup-process",
  // //       icon: ""
  // //     },
  // //     {
  // //       text: "Courier",
  // //       link: "/docket-tracking-panel/courier",
  // //       icon: ""
  // //     },
  // //     {
  // //       text: "Shipment",
  // //       link: "/docket-tracking-panel/shipment",
  // //       icon: ""
  // //     }
  // //   ]
  // // },
  // {
  //   text: "Line Application",
  //   link: "/line-application",
  //   icon: DashboardIcon,
  //     // <DialIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[3], fontSize: 20 }}
  //     // />

  //   child: [
  //     {
  //       text: "Application",
  //       link: "/line-application/applicattion",
  //       icon: ""
  //     },
  //     {
  //       text: "Underwriting Panel",
  //       link: "/line-application/underwriting-panel",
  //       icon: ""
  //     }
  //   ]
  // },
  // {
  //   text: "Full Payment Transection",
  //   link: "/full-payment-transection",
  //   icon: DashboardIcon
  //     // <MoneyIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //     // />

  // },
  // {
  //   text: "Credit Card EMI",
  //   link: "/credit-card-emi",
  //   icon: DashboardIcon
  //     // <CreditCardIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[1], fontSize: 20 }}
  //     // />

  // },
  // {
  //   text: "Loans",
  //   link: "/loans",
  //   icon: DashboardIcon
  //     // <LoanIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[2], fontSize: 20 }}
  //     // />

  // },
  // {
  //   text: "Payments Section",
  //   link: "/payment-panel",
  //   icon: DashboardIcon,
  //     // <DocIcon
  //     //   className="mx-auto"
  //     //   style={{ color: SIDEBAR_COLORS[3], fontSize: 20 }}
  //     // />

  //   child: [
  //     {
  //       text: "Payments",
  //       link: "/payment-panel/payment",
  //       icon: (
  //         <MoneyIcon
  //           className="mx-auto"
  //           style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //         />
  //       )
  //     },
  //     {
  //       text: "Razorpay Payment",
  //       link: "/payment-panel/razorpay",
  //       icon: (
  //         <MoneyIcon
  //           className="mx-auto"
  //           style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //         />
  //       )
  //     },
  //     {
  //       text: "Payu Payment",
  //       link: "/payment-panel/payu",
  //       icon: (
  //         <MoneyIcon
  //           className="mx-auto"
  //           style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //         />
  //       )
  //     },
  //     {
  //       text: "Paytm payment",
  //       link: "/payment-panel/paytm",
  //       icon: (
  //         <MoneyIcon
  //           className="mx-auto"
  //           style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //         />
  //       )
  //     },
  //     {
  //       text: "Cash Detail Listings",
  //       link: "/payment-panel/cash_detail_listing",
  //       icon: (
  //         <MoneyIcon
  //           className="mx-auto"
  //           style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //         />
  //       )
  //     },
  //     {
  //       text: "Cash In Store Listing",
  //       link: "/payment-panelcash_in_store_listing",
  //       icon: (
  //         <MoneyIcon
  //           className="mx-auto"
  //           style={{ color: SIDEBAR_COLORS[0], fontSize: 20 }}
  //         />
  //       )
  //     }
  //   ]
  // },
];
